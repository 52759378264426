<template>
  <div>
    <template v-if="$slots.collapse">
      <!-- eslint-disable -->
      <Collapse :when="isExpanded" :baseHeight="props.height" class="v-collapse">
        <div :class="{'line-clamp-4': !isExpanded && lineClamp}">
          <slot name="collapse" />
        </div>
      </Collapse>
      <!-- eslint-disable -->
    </template>
    <div class="cursor-pointer" @click="isExpanded = !isExpanded">
      <template v-if="!isExpanded">
        <slot name="inactive">
          <div class="mt-4 font-semibold underline text-base md:text-lg">
            {{ t('show_more') }}
          </div>
        </slot>
      </template>
      <template v-if="isExpanded">
        <slot name="active">
          <div class="mt-4 font-semibold underline text-base md:text-lg">
            {{ t('show_less') }}
          </div>
        </slot>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Collapse } from 'vue-collapsed'

const t = useTranslations('UiTextMultiLineCollapse').translate
const isExpanded = ref(false)

const props = defineProps({
  height: {
    type: Number,
    default: 95
  },
  lineClamp: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss">
.v-collapse {
  transition: height var(--vc-auto-duration) ease-out;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
}
</style>
